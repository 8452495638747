import { Dictionary } from '../../utils/languages';

const translations = {
  'Total Supply': {
    ENG: 'Total Supply'
  },
  'Total Borrow': {
    ENG: 'Total Borrow'
  },
  'Utilization Rate': {
    ENG: 'Utilization Rate'
  },
  'Supply APY': {
    ENG: 'Supply APY'
  },
  'Borrow APY': {
    ENG: 'Borrow APY'
  },
  'Farming APY': {
    ENG: 'Farming APY'
  }
} as Dictionary;

export default translations;
