import React from 'react';
import TransactionUpdater from './transactions/updater';

const Updaters = () => (
  <>
    <TransactionUpdater />
  </>
);

export default Updaters;
